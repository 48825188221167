import {logError, logInfo} from '@/utilities/log';
import {withDeduplication} from '@/utilities/with-deduplication';
import {
  InitOptions,
  Session,
  initRecharge,
  loginShopifyAppProxy,
} from '@rechargeapps/storefront-client';

let isInitialized = false;

/**
 * Session data for the Recharge Storefront Client
 * This is initially empty, but will be populated after the client is initialized.
 * NOTE: the client is automatically intilalized when this module is imported.
 */
export let session: Session = {
  apiToken: '',
  customerId: '',
  message: '',
};

/**
 * Initializes the Recharge Storefront Client
 * @param options - Options for initializing the Recharge Storefront Client
 * @see https://storefront.rechargepayments.com/client/docs/getting_started/package_setup/
 */
export async function initializeRecharge(options: InitOptions) {
  if (!isInitialized) {
    initRecharge({
      loginRetryFn,
      ...options,
    });
    await loadSession();
    isInitialized = true;
  }
}

/**
 * Retry function for logging in to the Recharge Storefront Client
 * If any Recharge API requests fail, the client will call this function to refresh the session,
 * then automatically retry the request.
 * @returns Session data for the Recharge Storefront Client
 */
async function loginRetryFn() {
  await loadSession();
  return session;
}

/**
 * Loads the session data for the Recharge Storefront Client
 * Uses the `withDeduplication` utility to prevent multiple requests for the same data
 * Note that this doesn't return the session data, but assigns it to the global `session` variable.
 * This allows the session data to be loaded once and used throughout the app.
 *
 * @returns a Promise that resolves when the session data is loaded
 *
 * @example Usage
 * ```ts
 * await loadSession();
 * console.log('Session loaded:', session);
 * ```
 */
async function loadSession() {
  try {
    session = await withDeduplication(
      'recharge_storefront_session',
      loginShopifyAppProxy
    );
    logInfo('LoadedRechargeSession', {session});
  } catch (error) {
    logError(error, {message: 'ErrorLoadingRechargeSession'});
  }
}

/**
 * Calls the given function with the current session data
 * If the current session data is not available, it will be loaded first.
 * Wrap all Recharge SDK calls with this decorator function for best performance.
 * This reduces the amount of 401 errors from the Recharge API to almost none.
 *
 * @param fn - The function to call with the session data
 * @returns The result of the given function
 *
 * @example Wrap a Recharge SDK call with `withSession`
 * ```ts
 * import {withSession} from '@/recharge-storefront/client';
 * import {getPlan} from '@rechargeapps/storefront-client';
 *
 * const plan = await withSession((session) => getPlan(session, sellingPlanId));
 * ```
 */
export async function withSession<T>(fn: (session: Session) => T): Promise<T> {
  if (!session.apiToken) {
    await loadSession();
  }
  return fn(session);
}
